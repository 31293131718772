import CMPHelper from '../CMP/CMPHelper';
import SSOHelper from '../SSO/SSOHelper';
import AdobeHelper from '../Adobe/AdobeHelper';
// eslint-disable-next-line import/no-relative-packages
import EBAConfig from '../EBAConfig/EBAConfig';

function EDDLEnricherFactory() {
    let cachedPageContext = null;
    let cachedPlatformContext = null;
    let cachedDeviceContext = null;
    let cachedBrowserContext = null;
    let touchpointbrandOverride = null;

    // ================
    //  hoisted publics
    // ================
    const getTouchpointbrand = () => touchpointbrandOverride || EBAConfig.fallback_touchpointbrand || 'vrt';

    const setTouchpointbrand = (touchpointbrand) => {
        touchpointbrandOverride = touchpointbrand;
        if (cachedPlatformContext) {
            cachedPlatformContext.data.touchpointbrand = touchpointbrand;
        }
    };

    // =========
    // privates
    // =========
    const addContext = (ev, aContext) => {
        const event = ev;
        if (!event.context) event.context = [];
        ev.context.push(aContext);
    };

    const addConsentContext = (ev) => {
        const consent = CMPHelper.getConsents();
        if (consent) {
            const consentContext = {
                schema: 'iglu:be.vrt/context_consent/jsonschema/1-1-0',
                data: { ...consent },
            };
            addContext(ev, consentContext);
        }
    };

    const addUserContext = (ev) => {
        const mid = SSOHelper.getMarketingID();
        const pty = SSOHelper.getProfileType();
        const ecid = AdobeHelper.getECID();
        if (mid || ecid || pty) {
            const userContext = {
                schema: 'iglu:be.vrt/context_user/jsonschema/1-1-0',
                data: {},
            };
            if (mid) userContext.data.marketingid = mid;
            if (pty) userContext.data.profiletype = pty;
            if (ecid) userContext.data.ecid = ecid;
            addContext(ev, userContext);
        }
    };

    const findContext = (ev, schemaStartsWith) => {
        if (ev.context) return ev.context.find((el) => el.schema.startsWith(schemaStartsWith));
        return null;
    };

    const addPageContext = (ev) => {
        const pageContext = findContext(ev, 'iglu:be.vrt/context_page/');
        if (!pageContext) {
            if (cachedPageContext) addContext(ev, cachedPageContext);
        } else {
            cachedPageContext = { ...pageContext };
        }
    };

    const addDebuggingContext = (ev, code) => {
        if (code) {
            const debuggingContext = {
                schema: 'iglu:be.vrt/context_debugger/jsonschema/1-1-0',
                data: { debuggercode: code },
            };
            addContext(ev, debuggingContext);
        }
    };

    const initPlatformContext = () => {
        cachedPlatformContext = {
            schema: 'iglu:be.vrt/context_platform/jsonschema/1-2-2',
            data: {
                platform: EBAConfig.platform || 'web',
                environment: EBAConfig.environment || 'unknown',
                touchpointbrand: getTouchpointbrand(),
            },
        };
        if (EBAConfig.platformvendor) cachedPlatformContext.data.platformvendor = EBAConfig.platformvendor;
        if (EBAConfig.platformtechnology) cachedPlatformContext.data.platformtechnology = EBAConfig.platformtechnology;
    };

    const initDeviceContext = () => {
        cachedDeviceContext = {
            schema: 'iglu:be.vrt/context_device/jsonschema/1-1-0',
            data: {
                operatingsystem: window.navigator?.platform || 'unknown',
                operatingsystemversion: 'unknown',
            },
        };
    };

    const initBrowserContext = () => {
        cachedBrowserContext = {
            schema: 'iglu:be.vrt/context_browser/jsonschema/1-1-0',
            data: {
                useragent: window.navigator?.userAgent || 'unknown',
                browserlanguage: window.navigator?.language?.substring(0, 2) || 'unknown',
            },
        };
        if (window.navigator?.cookieEnabled !== undefined) cachedBrowserContext.data.cookiesallowed = window.navigator.cookieEnabled;
    };

    const initPageContext = () => {
        cachedPageContext = {
            schema: 'iglu:be.vrt/context_page/jsonschema/1-1-0',
            data: {
                pagename: 'fallback',
                pageurl: window.location.origin + window.location.pathname,
                touchpointbrand: EBAConfig.fallback_touchpointbrand || 'vrt',
                contentbrand: 'vrt',
                pagetitle: document.title,
                domain: window.location.hostname,
                pagepath: window.location.pathname,
            },
        };
    };

    initPlatformContext();
    initDeviceContext();
    initBrowserContext();
    initPageContext();

    // =========
    // publics
    // =========
    const enrichEvent = (ev, options = {}) => {
        addConsentContext(ev);
        addUserContext(ev);
        addPageContext(ev);
        addContext(ev, cachedPlatformContext);
        addContext(ev, cachedDeviceContext);
        addContext(ev, cachedBrowserContext);
        if (options.remoteDebuggingCode) addDebuggingContext(ev, options.remoteDebuggingCode);
        return ev;
    };

    return {
        enrichEvent,
        setTouchpointbrand,
        getTouchpointbrand,
    };
}

export default EDDLEnricherFactory();
