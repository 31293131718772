/* eslint-disable no-underscore-dangle */
import logger from 'loglevel';

function AdobeHelperFactory() {
    let ecid = null;

    const isAdobeReady = () => window._satellite && window._satellite.getVisitorId();

    const syncGetECID = () => {
        try {
            if (isAdobeReady()) ecid = window._satellite.getVisitorId().getMarketingCloudVisitorID();
            return ecid;
        } catch (e) {
            logger.warn('EBA AdobeHelper: exception in syncGetECID()');
            return false;
        }
    };

    const getECID = () => {
        if (!ecid) syncGetECID();
        return ecid;
    };

    return {
        getECID,
    };
}

export default AdobeHelperFactory();
